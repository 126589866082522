import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import { style } from "glamor";

const ContactData = contactPageData.contactSection;

function Contact(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                alt=""
              />
            </div>
            <div className="contact-heading-text-div">
              <h1 className="contact-heading-text" style={{ color: theme.text }}>
                {ContactData["title"]}
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {ContactData["description"]}
              </p>
              <SocialMedia />
              <br />

              <div className="contact-form-div">
  <form action="YOUR_FORMSPREE_ENDPOINT" method="POST" className="contact-form">
    <input type="text" name="name" placeholder="Name" required className="input-field" style={{ color: theme.text, backgroundColor: theme.body }} />
    <input type="email" name="_replyto" placeholder="Email" required className="input-field" style={{ color: theme.text, backgroundColor: theme.body }} />
    <textarea name="message" placeholder="Your Message" required className="textarea-field" style={{ color: theme.text, backgroundColor: theme.body }}></textarea>
    <div className="general-btn-div">
        <button type="submit" className="general-btn" style={{ background: theme.accentBright }}>Send</button>
    </div>
  </form>
</div>



            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
