/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
};

const greeting = {
  title: "Hello..",
  logo_name: "DevArtistry",
  full_name: "DevArtistry",
  subTitle: "Providing top-notch software solutions and services.",
  mail: "mailto:umer.nulled@gmail.com",
};

const achievements = {
  data: [
   
    {
      title: "100+ Successful Projects",
      description: "Successfully delivered over 100 projects across various domains, receiving commendations from clients globally.",
    },
    {
      title: "10K+ Active Users",
      description: "Our applications and solutions serve over 10,000 active users daily.",
    },
    {
      title: "5+ Years in Business",
      description: "Consistently serving clients with dedication and excellence for over 5 years.",
    },
  ],
};



// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Our projects make use of a vast variety of latest technology tools. Our best experiences are to create NodeJS Microservices, Laravel SaaS Products, Rest APIs, ERP and POS Systems. Below are some of our projects. Click on the project card to be redirected to respective links",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    profile_image_path: "dev4.png",
    description:
      "You can contact us at the places mentioned below. We will try to get back to you as fast as I can. ",
  },
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/umersaeed3d",
  linkedin: "https://www.linkedin.com/in/umer-saeed-3d/",
  gmail: "umersaeed3d@gmail.com",
  facebook: "https://www.facebook.com/extrovertix.cyber/",
  instagram: "https://www.instagram.com/nulled_ragin/",
};

const projects = {
  data: [
    {
      id: "-5",
      name: "custom-shopify-apps",
      url: "#",
      description:
        "Developed 10+ custom shopify apps with Laravel/Node, React involving UI & Checkout Extensions",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Node",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "-6",
      name: "tZero",
      url: "https://www.tzero.com/",
      description:
        "tZERO is a financial technology company providing transformative market-based solutions for companies, entrepreneurs, and retail & institutional investors.",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        
        {
          name: "Node",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "-4",
      name: "custom-wordpress-plugins",
      url: "#",
      description:
        "Developed 10+ custom plugins involving modification of woocommerce, Stripe & Paypal integartion with subscriptions, dummy order creation etc",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Wordpress",
          iconifyClass: "logos-wordpress",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "-3",
      name: "strainsecure",
      url: "https://trutrace.co/pricing-strainsecure/",
      description:
        "The first blockchain traceability software for cannabis that eliminates risk while delivering customer confidence in your brand",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      id: "-2",
      name: "trutrace",
      url: "https://trutrace.co/pricing-trutrace/",
      description:
        "The First Blockchain Traceability software that eliminates risk while delivering customer confidence in your brand",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      id: "-1",
      name: "orionone",
      url: "https://orionone.co/",
      description:
        "Your real-time collaborative SmartHub for logistics that uses blockchain, computer intelligence and machine learning to deliver true track & trace.",
      associated_with: "SolCoders",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "AWS",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      id: "0",
      name: "tow-revo",
      url: "http://towrevo.com/",
      description:
        "Developed Rest APIs & Admin Dashboard for this hybrid mobile application on Laravel.",
      associated_with: "Outsource to Asia LLC",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "3",
      name: "pos-with-ims",
      url: "http://laravel-pos-ims.herokuapp.com/",
      description:
        "A POS system with Inventory Management implemented on Laravel framework.",
      associated_with: "Freelancing",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "1",
      name: "erp-system",
      url: "http://erp-system-laravel.herokuapp.com/",
      description:
        "Developed this ERP System having common ERP features on Laravel framework.",
      associated_with: "Kreatix Solutions",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "2",
      name: "payroll-system",
      url: "#",
      description:
        "A complete payroll system implemented on Laravel framework. Locally deployed at one of the corporate office in Karachi.",
      associated_with: "PKSOL",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },

    {
      id: "4",
      name: "order-and-product-microservices",
      url: "https://store-web-interface-test.herokuapp.com/",
      description:
        "Secure microservices for a US based food startup implemented on Node.js. This startup is not yet in market.",
      associated_with: "Pecan LLC",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "5",
      name: "food-order-web-interface",
      url: "https://store-web-interface-test.herokuapp.com/",
      description:
        "A React and TypeScript web interface of the food order app.",
      associated_with: "Pecan LLC",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "TypeScript",
          iconifyClass: "logos-typescript",
        },
      ],
    },
    {
      id: "6",
      name: "food-order-customer-app",
      url: "#",
      description: "A react native food order app.",
      associated_with: "Pecan LLC",
      languages: [
        {
          name: "React-Native",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "7",
      name: "trello-gamification",
      url: "#",
      description:
        "A dashboard containing game based on trello scores for employees within an organization.",
      associated_with: "PKSOL",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    
  ],
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developing scalable application backend in Node, NestJS and Laravel",
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },

        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },

        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },

        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#029FCE",
          },
        },
      ],
    },
    {
      title: "Mobile App Development",
      fileName: "ReactNativeImg",
      skills: [
        "⚡ Develop efficient cross-platform mobile applications.",
        "⚡ Ensure smooth UI/UX design for optimal mobile experience.",
        "⚡ Integration of third-party APIs and components.",
        "⚡ App performance optimization for speed and scalability.",
      ],
      softwareSkills: [
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: { color: "#61DAFB" },
        },
        {
          skillName: "Expo",
          fontAwesomeClassname: "simple-icons:expo",
          style: { color: "#000020" },
        },
        // ... Add other tools & technologies specific to React Native development
      ],
    },
    {
      title: "WordPress Development",
      fileName: "WordPressImg",
      skills: [
        "⚡ Custom plugin development for tailored website functionalities.",
        "⚡ SEO enhancements and optimizations.",
        "⚡ Integration with various third-party services and APIs.",
        "⚡ Security and performance optimizations.",
      ],
      softwareSkills: [
        {
          skillName: "WordPress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: { color: "#0073aa" },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: { color: "#7377AD" },
        },
        {
          skillName: "WooCommerce",
          fontAwesomeClassname: "simple-icons:woocommerce",
          style: { color: "#96588a" },
        },
        // ... Add other tools & technologies related to WordPress
      ],
    },
    {
      title: "Shopify App Development",
      fileName: "ShopifyImg",
      skills: [
        "⚡ Enhance e-commerce functionalities for better user experience.",
        "⚡ Custom app development to increase sales and conversions.",
        "⚡ Seamless integration with other platforms and services.",
        "⚡ Performance optimizations for faster shop loading.",
      ],
      softwareSkills: [
        {
          skillName: "Shopify",
          fontAwesomeClassname: "simple-icons:shopify",
          style: { color: "#7ab55c" },
        },
        {
          skillName: "Liquid",
          fontAwesomeClassname: "simple-icons:liquid",
          style: { color: "#68d8d6" },
        },
        // ... Add other tools & technologies related to Shopify
      ],
    },
    
    
    
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};


export {
  settings,
  greeting,
  skills,
  projectsHeader,
  contactPageData,
  projects,
  achievements,
  socialMediaLinks
};
