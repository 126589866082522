import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import { achievements } from "../../portfolio.js";
import './Achievements.css'; // Importing new stylesheet for Achievements

function Achievements(props) {
  const theme = props.theme;
  return (
    <div className="achievements-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="basic-achievements">
        <Fade bottom duration={2000} distance="40px">
          {/* Heading Div */}
          <div className="heading-div">
            <div className="heading-img-div">
              {/* If you have a specific Achievement Image, include it here */}
              {/* <AchievementsImg theme={theme} /> */}
            </div>
            <div className="heading-text-div">
              <h1 className="heading-text" style={{ color: theme.text }}>Achievements</h1>
              <h3 className="heading-sub-text" style={{ color: theme.text }}>Milestones and Accomplishments</h3>
              <p className="achievements-header-detail-text subTitle" style={{ color: theme.secondaryText }}>
                Over the years, our dedication and hard work have been recognized in various forms. Here are some of the milestones we're proud of.
              </p>
            </div>
          </div>
          {/* Achievements Grid */}
          <div className="achievements-grid">
            {achievements.data.map((achievement, index) => (
              <div key={index} className="achievement-card" style={{backgroundColor: theme.body}}>
                <div className="achievement-icon">
                  {/* <YourAchievementIconHere /> */}
                </div>
                <h4 style={{ color: theme.text,backgroundColor: theme.body }}>{achievement.title}</h4>
                <p style={{ color: theme.secondaryText, backgroundColor: theme.body }}>{achievement.description}</p>
              </div>
            ))}
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Achievements;
